/* Keyframe animation */
@keyframes slideIn {
    from {
        width: 0;
    }
    to {
        width: 70vw;
    }

}
.sidenavbar{
    animation: slideIn 0.5s forwards;
}